<template>
    <div class="flex flex-col justify-center px-[291px] py-[134px] h-auto">
        <div class="grid grid-cols-2 gap-[64px]">
          <section class="flex flex-col gap-3 p-10 2xl-1440:p-7 2xl-1440:h-[210px]">
            <div class="flex justify-start items-center gap-2">
              <img src="/img/presentacion/2.th.help-desk.svg" class="h-12 w-12 2xl-1440:h-10 2xl-1440:w-10">
              <h1 class="text-[41px] 2xl-1440:text-[31px] font-semibold">70%</h1>
            </div>
            <div class="border-separation-mid"></div>
            <h1 class="text-[35px] 2xl-1440:text-[25px] font-semibold">Reducción de las <br>preguntas en recepción</h1>
          </section>
          <section class="flex flex-col gap-3 p-10 2xl-1440:p-7 2xl-1440:h-[210px]">
            <div class="flex justify-start items-center gap-2">
              <img src="/img/presentacion/2.th.hour-glass.svg" class="h-12 w-12 2xl-1440:h-10 2xl-1440:w-10">
              <h1 class="text-[41px] 2xl-1440:text-[31px] font-semibold">80%</h1>
            </div>
            <div class="border-separation-mid"></div>
            <h1 class="text-[35px] 2xl-1440:text-[25px] font-semibold">Reducción del tiempo en gestionar la reputación</h1>
          </section>
        </div>
        <div class="grid grid-cols-2 gap-[64px] mt-[32px]">
          <section class="flex flex-col gap-3 p-10 2xl-1440:p-7 2xl-1440:h-[210px]">
            <div class="flex justify-start items-center gap-2">
              <img src="/img/presentacion/2.th.happy-face.svg" class="h-12 w-12 2xl-1440:h-10 2xl-1440:w-10">
              <h1 class="text-[41px] 2xl-1440:text-[31px] font-semibold">20%</h1>
            </div>
            <div class="border-separation-mid"></div>
            <h1 class="text-[35px] 2xl-1440:text-[25px] font-semibold">Mejora de la experiencia <br> del huésped</h1>
          </section>
          <section class="flex flex-col gap-3 bg-[#34A98F] pb-[1px] px-10 pt-10 2xl-1440:p-7 h-[273px] 2xl-1440:h-[210px]" style="border-radius: 20px;">
            <div class="flex justify-start items-center gap-2">
              <img src="/img/presentacion/2.th.analitics.svg" class="h-12 w-12 2xl-1440:h-10 2xl-1440:w-10">
              <span class="text-[41px] 2xl-1440:text-[31px] font-semibold text-white">10%</span>
            </div>
            <div class="border-separation-mid-white"></div>
            <span class="text-[35px] 2xl-1440:text-[25px] font-semibold text-white">Mejora de la reputación  <br> online</span>
          </section>
        </div>
    </div>
</template>