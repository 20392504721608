<template>
  <div class="flex justify-center items-center mt-[40px] 2xl-1440:mt-[15px] pr-[240px] pl-[240px] 2xl-1440:pr-[140px] 2xl-1440:pl-[140px]">
    <div class="grid grid-cols-2 gap-[64px]">
      <!-- Columna WebApp -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[28px] 2xl-1440:text-[21px] custom-span">WebApp</h1>
        <hr class="border border-2 border-black">
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/icons/2.TH.Item_icon_Card_Pricing.svg" alt="Icono Alojamiento" class="w-[64px] h-[64px] mr-4">
          <div>
            <h1 class="text-[29px] font-semibold 2xl-1440:text-[20px]">Alojamiento</h1>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Todo sobre tu hotel</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/destino.svg" alt="Icono Destino" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Destino</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Tu guía personalizada de la ciudad</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/experiencias.svg" alt="Icono Experiencias" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Experiencias</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Experiencias únicas en la ciudad</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/suite.svg" alt="Icono Suite de comunicación" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Chat</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Comunicación directa con tus huéspedes</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/seguimiento.svg" alt="Icono Seguimiento" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Seguimiento</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Más reseñas positivas y menos negativas</p>
          </div>
        </div>
      </div>

      <!-- Columna Hoster -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[28px] 2xl-1440:text-[21px] custom-span">Hoster</h1>
        <hr class="border border-2 border-black">
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/gestion.svg" alt="Icono Gestión de reseñas" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Reseñas</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Agiliza las reseñas de tu hotel</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/estancias2.png" alt="Icono Gestión de estancias" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Estancias</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Gestiona tus huéspedes y sus estancias</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] ">
          <img src="/img/presentacion/2.TH.Item_icon_Card_Pricing.png" alt="Icono Gestión de estancias" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Comunicaciones</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Promociona tu WebApp por diferentes canales</p>
          </div>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 2xl-1440:px-4 flex items-center rounded-[10px] relative">
          <img src="/img/presentacion/webapp.svg" alt="Icono Webapp Personalizada" class="w-[64px] h-[64px] mr-4">
          <div>
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Personalización</p>
            <p class="text-[20px] 2xl-1440:text-[14px] font-medium">Modifica tu WebApp fácilmente</p>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="text-[29px] font-semibold 2xl-1440:text-[20px] text-right custom-span">
            <span class="text-[34px] custom-span">8</span>.99€
          </span>
          <span class="text-[28px] font-semibold text-right custom-span">Por habitación al mes</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-span {
  color: inherit !important;
  font-family: 'Rethink Sans', sans-serif !important;
}

p, h1, h2 {
  font-family: 'Rethink Sans', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@600&display=swap');
</style>
