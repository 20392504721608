<template>
  <!-- Contenedor principal con separación top de 40px -->
  <div class="flex flex-col justify-center items-center h-[760px] pb-20 w-full mt-[40px]">
    <!-- Contenedor interno con ancho completo -->
    <div class="w-full max-w-[1680px] mx-auto px-0">
      <div class="mb-8">
        <div class="flex flex-col gap-3 mb-2">
          <p class="font-medium text-[50px] text-[#333333] mt-[69px]">
            Mejorando la experiencia de tus huéspedes mediante la digitalización de sus estancias
          </p>
        </div>
      </div>

      <div class="flex gap-[64px] 2xl-1440:gap-[30px] w-full">
        <section class="flex flex-col 2xl-1440:p-0 justify-center w-1/3">
          <div class="flex justify-start items-center gap-2 mb-4">
            <img src="/img/presentacion/2.th.webapp-hoster.svg" class="h-12 w-12 svg-black" alt="Plataformas">
            <h1 class="text-[41px] 2xl-1440:text-[35px] font-semibold">2 Plataformas</h1>
          </div>
          <hr class="border border-2 border-[#333] mb-4">
          <h1 class="text-[35px] 2xl-1440:text-[29px] font-semibold">WebApp - Hoster</h1>
        </section>
        <!-- Imagen de las plataformas -->
        <div class="flex w-2/3">
          <div class="flex gap-4">
            <div class="flex-col justify-start items-start">
              <img src="/img/presentacion/phone.png" alt="WebApp" class="w-[232px] h-[480px] 2xl-1440:h-[375px] 2xl-1440:w-[200px]" />
            </div>
            <div class="flex-col justify-start items-start">
              <img src="/img/presentacion/web.png" alt="Hoster" class="w-[875px] h-[470px] 2xl-1440:h-[375px] 2xl-1440:w-[800px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>