<template>
    <!-- Contenedor principal centrado verticalmente con padding automático -->
    <div class="flex flex-col justify-center items-center h-[760px] pb-20 w-full ">
        <!-- Contenedor interno con ancho completo -->
      <div class="w-full max-w-[1680px] mx-auto px-0">
        <div class="mb-8">
          <div class="flex flex-col gap-3 mb-6">
            <p class="font-medium text-[50px] text-[#333333] mt-[69px]">
              Ayudamos a mejorar y gestionar la reputación online de los hoteles
            </p>
          </div>
        </div>

        <div class="flex gap-[64px] 2xl-1440:gap-[30px] w-full">
          <section class="flex flex-col w-3/5 2xl-1440:w-1/2">
            <div class="mb-[64px] 2xl-1440:mb-[25px]">
              <h1 class="text-[35px] font-semibold mb-[20px] 2xl-1440:text-[25px]">¿Qué es la reputación online?</h1>
              <p class="text-[24px] font-medium text-[#333333]">
                La percepción de calidad y confianza que un hotel genera entre los usuarios de plataformas digitales
              </p>
            </div>
            <div>
              <h1 class="text-[35px] font-semibold mb-[20px] 2xl-1440:text-[25px]">¿Qué elementos la componen?</h1>
              <div class="flex gap-6">
                <section class="flex flex-col gap-3 w-full">
                  <img src="/img/presentacion/2.th.nota-media.svg" class="h-10 w-10 svg-black" alt="Nota media">
                  <hr class="border border-2 border-[#333]">
                  <p class="text-[24px] font-medium">Nota media</p>
                </section>
                <section class="flex flex-col gap-3 w-full">
                  <img src="/img/presentacion/2.th.respuestas-reseñas.svg" class="h-10 w-10 svg-black" alt="Número de reseñas">
                  <hr class="border border-2 border-[#333]">
                  <p class="text-[24px] font-medium">Número de reseñas</p>
                </section>
                <section class="flex flex-col gap-3 w-full">
                  <img src="/img/presentacion/2.th-numero-reseñas.svg" class="h-10 w-10 svg-black" alt="Tasa de respuesta">
                  <hr class="border border-2 border-[#333]">
                  <p class="text-[24px] font-medium">Tasa de respuesta</p>
                </section>
              </div>
            </div>
          </section>
          <img src="/img/presentacion/quehacemos.png" class="h-auto 2xl-1440:h-[350px]" alt="Que hacemos">
        </div>
      </div>
    </div>
</template>