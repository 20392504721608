<template>
  <div class="relative w-full h-screen overflow-hidden bg-[#FFFFFF]" @wheel="handleScroll">
    <!-- Header con el título a la izquierda y el logo a la derecha -->
    <div v-if="currentSlide && currentSlide.header" class="absolute top-0 left-0 w-full z-20 bg-[#FFFFFF] pr-[120px] pl-[120px]">
      <div class="flex justify-between items-center pt-[24px] pb-[24px] pr-[40px]">
        <h2 class="text-[24px] font-normal">{{ currentSlide.title }}</h2>
        <img src="/img/1.TH.logo.svg" alt="Logo" class="h-[29px] object-contain" />
      </div>
      <hr class="border-gray-300 pl-[40px] pr-[40px]" />
    </div>

    <!-- Diapositiva fija con el logo central -->
    <div
      v-if="currentIndex === 0"
      class="relative flex flex-col justify-center bg-[#FFFFFF] w-full mx-auto"
      style="height: 820px; margin-top: 40px;"
    >
      <div class="flex-grow flex items-center justify-center">
        <img src="/img/presentacion/1.TH.logo.png" alt="Logo Central" class="max-w-full max-h-[110px] object-contain" />
      </div>
    </div>

    <!-- Diapositiva dinámica con transición -->
    <transition :name="transitionName" mode="out-in">
      <div
        v-if="currentSlide"
        :key="currentSlide.name"
        class="relative flex flex-col justify-center bg-[#FFFFFF] mx-auto"
        style="height: 760px; margin-top: 100px;" 
        :class="{'w-full': currentSlide.fullWidth, 'max-w-[1680px]': !currentSlide.fullWidth}"
      >
        <slot :name="currentSlide.name"></slot>
      </div>
    </transition>

    <!-- Contenedor de los botones de navegación siempre visible -->
    <div class="w-full max-w-[1680px] mx-auto mt-[40px] flex justify-end">
      <button
        @click="prevSlide"
        :class="[isFirstSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-l-lg border-none focus:outline-none']"
        :disabled="isFirstSlide"
      >
        <img src="/img/presentacion/up.svg" alt="Up" class="w-8 h-8" />
      </button>
      <button
        @click="nextSlide"
        :class="[isLastSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-r-lg border-none focus:outline-none']"
        :disabled="isLastSlide"
      >
        <img src="/img/presentacion/down.svg" alt="Down" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

const props = defineProps({
  useScroll: {
    type: Boolean,
    default: false,
  },
  showButtons: {
    type: Boolean,
    default: true,
  }
});

const currentIndex = ref(0);
const transitionName = ref('slide-up-down');

// Arreglo de diapositivas dinámicas
const dynamicSlides = [
  { name: 'slide-1', header: true, layout: 'col', title: '¿QUÉ HACEMOS?', fullWidth: true },
  { name: 'slide-2', header: true, layout: 'col', title: '¿CÓMO LO HACEMOS?', fullWidth: false },
  { name: 'slide-3', header: true, layout: 'col', title: 'RETORNO', fullWidth: false },
  { name: 'slide-4', header: true, layout: 'col', title: 'PRICING', fullWidth: true },
  { name: 'slide-5', header: true, layout: 'row', title: 'CALCULADORA DE BENEFICIO', fullWidth: false },
];

const shortMode = ref(false);

// Filtrar diapositivas dinámicas si shortMode está activado
const filteredSlides = computed(() => {
  return shortMode.value ? dynamicSlides.slice(0, 3) : dynamicSlides;
});

const isFirstSlide = computed(() => currentIndex.value === 0);
const isLastSlide = computed(() => currentIndex.value === filteredSlides.value.length);

// Computed property to get the current slide based on the currentIndex
const currentSlide = computed(() => {
  return filteredSlides.value[currentIndex.value - 1] || null;
});

const prevSlide = () => {
  if (currentIndex.value > 0) {
    transitionName.value = 'slide-down-up';  // Animación que hará que la página se desplace hacia abajo
    currentIndex.value--;
  }
};

const nextSlide = () => {
  if (currentIndex.value < filteredSlides.value.length) {
    transitionName.value = 'slide-up-down';  // Animación que hace que la página se desplace hacia arriba
    currentIndex.value++;
  }
};

let isScrolling = false;

const handleScroll = (event) => {
  if (props.useScroll && !isScrolling) {
    isScrolling = true;
    if (event.deltaY < 0) {
      prevSlide();
    } else if (event.deltaY > 0) {
      nextSlide();
    }
    setTimeout(() => {
      isScrolling = false;
    }, 620);
  }
};

const handleKeydown = (event) => {
  if (event.key === 'ArrowUp') {
    prevSlide();
  } else if (event.key === 'ArrowDown') {
    nextSlide();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);

  const queryParams = new URLSearchParams(window.location.search);
  shortMode.value = queryParams.get('short') === '1';
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});
</script>

<style scoped>
/* Animación cuando avanzamos (Down), la página sube */
.slide-up-down-enter-active, .slide-up-down-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-up-down-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-down-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Animación cuando retrocedemos (Up), la página baja */
.slide-down-up-enter-active, .slide-down-up-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-down-up-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-down-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

/* Estilos de los botones de navegación */
.button-active {
  position: relative;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
}

.button-active:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #8D8D8D;
  opacity: 0.12;
}

.button-active:hover img {
  filter: brightness(0);
}

.button-active img {
  position: relative;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
